import React, { Component, Suspense, lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import Spinner from "../app/shared/Spinner";

const Dashboard = lazy(() => import("./dashboard/Dashboard"));

const Buttons = lazy(() => import("./basic-ui/Buttons"));
const Dropdowns = lazy(() => import("./basic-ui/Dropdowns"));
const Typography = lazy(() => import("./basic-ui/Typography"));

const BasicElements = lazy(() => import("./form-elements/BasicElements"));

const BasicTable = lazy(() => import("./tables/BasicTable"));

const Mdi = lazy(() => import("./icons/Mdi"));

const ChartJs = lazy(() => import("./charts/ChartJs"));

const Error404 = lazy(() => import("./error-pages/Error404"));
const Error500 = lazy(() => import("./error-pages/Error500"));

const Login = lazy(() => import("./user-pages/Login"));
const Register1 = lazy(() => import("./user-pages/Register"));
const Lockscreen = lazy(() => import("./user-pages/Lockscreen"));

const BlankPage = lazy(() => import("./general-pages/BlankPage"));

const MailBox = lazy(() => import("./mailbox/MailBox"));
const MailBoxDetail = lazy(() => import("./mailbox/MailBoxDetail"));
const Member = lazy(() => import("./member/Member"));
const Routemenu = lazy(() => import("./routemenu/Routemenu"));
const Setting = lazy(() => import("./setting/Setting"));

const Role = lazy(() => import("./setting-ui/Role"));
const EV = lazy(() => import("./setting-ui/EV"));
const EmailPage = lazy(() => import("./setting-ui/EmailPage"));
const Question = lazy(() => import("./setting-ui/Question"));
// const Pdpa = lazy(() => import('./setting-ui/Pdpa'));
const Pdpa = lazy(() => import("./page/pdpa"));
const Contact = lazy(() => import("./setting-ui/Contact"));
const User = lazy(() => import("./setting-ui/User"));

const Faq = lazy(() => import("./page/Faq"));
const Blog = lazy(() => import("./page/Blog"));
const Routes = lazy(() => import("./page/Routes"));

class AppRoutes extends Component {
  render() {
    return (
      <Suspense fallback={<Spinner />}>
        <Switch>
          <Route path="/user-pages/login-1" component={Login} />
          <Route exact path="/dashboard" component={Dashboard} />
          {/* <Route exact path="/user-pages/login-1" component={ Login } />
          <Route path="/dashboard" component={ Dashboard } /> */}

          <Route path="/basic-ui/buttons" component={Buttons} />
          <Route path="/basic-ui/dropdowns" component={Dropdowns} />
          <Route path="/basic-ui/typography" component={Typography} />

          <Route
            path="/form-Elements/basic-elements"
            component={BasicElements}
          />

          <Route path="/tables/basic-table" component={BasicTable} />

          <Route path="/icons/mdi" component={Mdi} />

          <Route path="/charts/chart-js" component={ChartJs} />

          <Route path="/user-pages/register-1" component={Register1} />
          <Route path="/user-pages/lockscreen" component={Lockscreen} />

          <Route path="/error-pages/error-404" component={Error404} />
          <Route path="/error-pages/error-500" component={Error500} />

          <Route path="/general-pages/blank-page" component={BlankPage} />

          <Route path="/mailbox" component={MailBox} />
          <Route path="/mailbox_detail/:id" component={MailBoxDetail} />

          <Route path="/member" component={Member} />
          <Route path="/routemenu" component={Routemenu} />
          <Route path="/setting" component={Setting} />

          <Route path="/setting-ui/user" component={User} />
          <Route path="/setting-ui/role" component={Role} />
          <Route path="/setting-ui/ev" component={EV} />
          <Route path="/setting-app/question" component={Question} />
          <Route path="/setting-ui/pdpa" component={Pdpa} />
          <Route path="/setting-app/contact" component={Contact} />
          <Route path="/setting-ui/emailpage" component={EmailPage} />

          <Route path="/setting-app/Faq" component={Faq} />
          <Route path="/setting-app/Blog" component={Blog} />
          <Route path="/setting-ui/Routes" component={Routes} />

          <Redirect to="/dashboard" />
        </Switch>
      </Suspense>
    );
  }
}

export default AppRoutes;
