import axios from "axios";
import AppConfig from "./AppConfig.js";
 

const http = axios.create({
    baseURL: AppConfig.api,
    timeout: 30000,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      "Content-Type": "application/json",
    },
});

export default http;