import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Collapse } from "react-bootstrap";
import { Trans } from "react-i18next";

class Sidebar extends Component {
  state = {
    login: null,
  };

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({ [menuState]: false });
    } else if (Object.keys(this.state).length === 0) {
      this.setState({ [menuState]: true });
    } else {
      Object.keys(this.state).forEach((i) => {
        this.setState({ [i]: false });
      });
      this.setState({ [menuState]: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (!this.state.login) {
      this.loadLogin();
    }

    // console.log('checkLogin->',this.state.login);
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  loadLogin() {
    let login = localStorage.getItem("login");
    console.log("loadLogin->", login);
    console.log("this.state.login->", this.state.login);
    // if(login){
    if (login == "" || login == null || login == "null") {
      console.log("else->", login);
      return (window.location.href = "/admin/user-pages/login-1");
    } else {
      console.log("if->", login);
      this.setState({ login: JSON.parse(login) });
    }
  }

  onRouteChanged() {
    document.querySelector("#sidebar").classList.remove("active");
    Object.keys(this.state).forEach((i) => {
      this.setState({ [i]: false });
    });

    const dropdownPaths = [
      { path: "/apps", state: "appsMenuOpen" },
      { path: "/basic-ui", state: "basicUiMenuOpen" },
      { path: "/setting-app", state: "setingUiMenuOpenApp" },
      { path: "/setting-ui", state: "setingUiMenuOpen" },
      { path: "/advanced-ui", state: "advancedUiMenuOpen" },
      { path: "/form-elements", state: "formElementsMenuOpen" },
      { path: "/tables", state: "tablesMenuOpen" },
      { path: "/maps", state: "mapsMenuOpen" },
      { path: "/icons", state: "iconsMenuOpen" },
      { path: "/charts", state: "chartsMenuOpen" },
      { path: "/user-pages", state: "userPagesMenuOpen" },
      { path: "/error-pages", state: "errorPagesMenuOpen" },
      { path: "/general-pages", state: "generalPagesMenuOpen" },
      { path: "/ecommerce", state: "ecommercePagesMenuOpen" },
    ];

    dropdownPaths.forEach((obj) => {
      if (this.isPathActive(obj.path)) {
        this.setState({ [obj.state]: true });
      }
    });
  }

  render() {
    return (
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <ul className="nav">
          {/* <li className="nav-item nav-profile">
            <a href="!#" className="nav-link" onClick={evt =>evt.preventDefault()}>
              <div className="nav-profile-image">
                <img src={ require("../../assets/images/faces/face1.jpg") } alt="profile" />
                <span className="login-status online"></span>  
              </div>
              <div className="nav-profile-text">
                <span className="font-weight-bold mb-2"><Trans>David Grey. H</Trans></span>
                <span className="text-secondary text-small"><Trans>Project Manager</Trans></span>
              </div>
              <i className="mdi mdi-bookmark-check text-success nav-profile-badge"></i>
            </a>
          </li> */}

          {this.state.login ? (
            this.state.login.role == "Admin" ? (
              <>
                <li
                  className={
                    this.isPathActive("/dashboard")
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <Link className="nav-link" to="/dashboard">
                    <span className="menu-title">
                      <Trans>ภาพรวมการทำงาน</Trans>
                    </span>
                    <i className="mdi mdi-home menu-icon"></i>
                  </Link>
                </li>
                <li
                  className={
                    this.isPathActive("/mailbox")
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <Link className="nav-link" to="/mailbox">
                    <span className="menu-title">
                      <Trans>ข้อความร้องเรียน และ ข้อเสนอแนะ</Trans>
                    </span>
                  </Link>
                </li>
                <li
                  className={
                    this.isPathActive("/setting-app")
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <div
                    className={
                      this.state.setingUiMenuOpenApp
                        ? "nav-link menu-expanded"
                        : "nav-link"
                    }
                    onClick={() => this.toggleMenuState("setingUiMenuOpenApp")}
                    data-toggle="collapse"
                  >
                    <span className="menu-title">
                      <Trans>การจัดการข้อมูลเมนู เพิ่มเติม</Trans>
                    </span>
                    <i className="menu-arrow"></i>
                  </div>
                  <Collapse in={this.state.setingUiMenuOpenApp}>
                    <ul className="nav flex-column sub-menu">
                      <li className="nav-item">
                        <Link
                          className={
                            this.isPathActive("/setting-app/Blog")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          to="/setting-app/Blog"
                        >
                          <Trans>ประชาสัมพันธ์</Trans>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className={
                            this.isPathActive("/setting-app/Faq")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          to="/setting-app/Faq"
                        >
                          <Trans>คำถามที่พบบ่อย</Trans>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className={
                            this.isPathActive("/setting-app/question")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          to="/setting-app/question"
                        >
                          <Trans>แบบสอบถามเพื่อปรับปรุงระบบ</Trans>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className={
                            this.isPathActive("/setting-app/contact")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          to="/setting-app/contact"
                        >
                          <Trans>ข้อมูลการติดต่อ</Trans>
                        </Link>
                      </li>
                    </ul>
                  </Collapse>
                </li>
                <li
                  className={
                    this.isPathActive("/setting-ui")
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <div
                    className={
                      this.state.setingUiMenuOpen
                        ? "nav-link menu-expanded"
                        : "nav-link"
                    }
                    onClick={() => this.toggleMenuState("setingUiMenuOpen")}
                    data-toggle="collapse"
                  >
                    <span className="menu-title">
                      <Trans>การตั้งค่า</Trans>
                    </span>
                    <i className="menu-arrow"></i>
                  </div>
                  <Collapse in={this.state.setingUiMenuOpen}>
                    <ul className="nav flex-column sub-menu">
                      <li className="nav-item">
                        <Link
                          className={
                            this.isPathActive("/setting-ui/ev")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          to="/setting-ui/ev"
                        >
                          <Trans>แผนที่สถานีอัดประจุไฟฟ้า</Trans>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className={
                            this.isPathActive("/setting-ui/Routes")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          to="/setting-ui/Routes"
                        >
                          <Trans>กำหนดค่าผ่านทาง</Trans>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className={
                            this.isPathActive("/setting-ui/emailpage")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          to="/setting-ui/emailpage"
                        >
                          <Trans>ตั้งค่า ACC SendMail</Trans>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className={
                            this.isPathActive("/setting-ui/pdpa")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          to="/setting-ui/pdpa"
                        >
                          <Trans>นโยบายข้อมูลส่วนบุคคล</Trans>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className={
                            this.isPathActive("/setting-ui/user")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          to="/setting-ui/user"
                        >
                          <Trans>การจัดการ Admin</Trans>
                        </Link>
                      </li>
                      {/* <li className="nav-item"> <Link className={ this.isPathActive('/setting-ui/role') ? 'nav-link active' : 'nav-link' } to="/setting-ui/role"><Trans>การจัดการสิทธิการทำงาน</Trans></Link></li> */}
                    </ul>
                  </Collapse>
                </li>

                <li
                  className={
                    this.isPathActive("/member")
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <Link className="nav-link" to="/member">
                    <span className="menu-title">
                      <Trans>ข้อมูลสมาชิก</Trans>
                    </span>
                  </Link>
                </li>
                <li
                  className={
                    this.isPathActive("/routemenu")
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <Link className="nav-link" to="/routemenu">
                    <span className="menu-title">
                      <Trans>รายงาน ข้อมูลการกดปุ่มเมนูเส้นทาง</Trans>
                    </span>
                  </Link>
                </li>
              </>
            ) : (
              <></>
            )
          ) : (
            <></>
          )}

          {this.state.login ? (
            this.state.login.role == "Supervisor" ? (
              <>
                <li
                  className={
                    this.isPathActive("/mailbox")
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <Link className="nav-link" to="/mailbox">
                    <span className="menu-title">
                      <Trans>ข้อความร้องเรียน และ ข้อเสนอแนะ</Trans>
                    </span>
                  </Link>
                </li>
                <li
                  className={
                    this.isPathActive("/setting-app")
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <div
                    className={
                      this.state.setingUiMenuOpenApp
                        ? "nav-link menu-expanded"
                        : "nav-link"
                    }
                    onClick={() => this.toggleMenuState("setingUiMenuOpenApp")}
                    data-toggle="collapse"
                  >
                    <span className="menu-title">
                      <Trans>การจัดการข้อมูลเมนู เพิ่มเติม</Trans>
                    </span>
                    <i className="menu-arrow"></i>
                  </div>
                  <Collapse in={this.state.setingUiMenuOpenApp}>
                    <ul className="nav flex-column sub-menu">
                      <li className="nav-item">
                        <Link
                          className={
                            this.isPathActive("/setting-app/Blog")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          to="/setting-app/Blog"
                        >
                          <Trans>ประชาสัมพันธ์</Trans>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className={
                            this.isPathActive("/setting-app/Faq")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          to="/setting-app/Faq"
                        >
                          <Trans>คำถามที่พบบ่อย</Trans>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className={
                            this.isPathActive("/setting-app/question")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          to="/setting-app/question"
                        >
                          <Trans>แบบสอบถามเพื่อปรับปรุงระบบ</Trans>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className={
                            this.isPathActive("/setting-app/contact")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          to="/setting-app/contact"
                        >
                          <Trans>ข้อมูลการติดต่อ</Trans>
                        </Link>
                      </li>
                    </ul>
                  </Collapse>
                </li>
                <li
                  className={
                    this.isPathActive("/setting-ui")
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <div
                    className={
                      this.state.setingUiMenuOpen
                        ? "nav-link menu-expanded"
                        : "nav-link"
                    }
                    onClick={() => this.toggleMenuState("setingUiMenuOpen")}
                    data-toggle="collapse"
                  >
                    <span className="menu-title">
                      <Trans>การตั้งค่า</Trans>
                    </span>
                    <i className="menu-arrow"></i>
                  </div>
                  <Collapse in={this.state.setingUiMenuOpen}>
                    <ul className="nav flex-column sub-menu">
                      <li className="nav-item">
                        <Link
                          className={
                            this.isPathActive("/setting-ui/ev")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          to="/setting-ui/ev"
                        >
                          <Trans>แผนที่สถานีอัดประจุไฟฟ้า</Trans>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className={
                            this.isPathActive("/setting-ui/Routes")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          to="/setting-ui/Routes"
                        >
                          <Trans>กำหนดค่าผ่านทาง</Trans>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className={
                            this.isPathActive("/setting-ui/pdpa")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          to="/setting-ui/pdpa"
                        >
                          <Trans>นโยบายข้อมูลส่วนบุคคล</Trans>
                        </Link>
                      </li>
                      {/* <li className="nav-item"> <Link className={ this.isPathActive('/setting-ui/role') ? 'nav-link active' : 'nav-link' } to="/setting-ui/role"><Trans>การจัดการสิทธิการทำงาน</Trans></Link></li> */}
                    </ul>
                  </Collapse>
                </li>

                <li
                  className={
                    this.isPathActive("/member")
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <Link className="nav-link" to="/member">
                    <span className="menu-title">
                      <Trans>ข้อมูลสมาชิก</Trans>
                    </span>
                  </Link>
                </li>
                <li
                  className={
                    this.isPathActive("/routemenu")
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <Link className="nav-link" to="/routemenu">
                    <span className="menu-title">
                      <Trans>รายงาน ข้อมูลการกดปุ่มเมนูเส้นทาง</Trans>
                    </span>
                  </Link>
                </li>

                {/* <li className={ this.isPathActive('/mailbox') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/mailbox">
              <span className="menu-title"><Trans>ข้อความร้องเรียน และ ข้อเสนอแนะ</Trans></span>
            </Link>
          </li>
          <li className={ this.isPathActive('/member') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/member">
              <span className="menu-title"><Trans>ข้อมูลสมาชิก</Trans></span>
            </Link>
          </li>
          <li className={ this.isPathActive('/routemenu') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/routemenu">
              <span className="menu-title"><Trans>ข้อมูลการกดปุ่มเมนูเส้นทาง</Trans></span>
            </Link>
          </li>

          <li className={ this.isPathActive('/Blog') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/Blog">
              <span className="menu-title"><Trans>ประชาสัมพันธ์</Trans></span>
            </Link>
          </li>
          <li className={ this.isPathActive('/Routes') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/Routes">
              <span className="menu-title"><Trans>กำหนดค่าผ่านทาง</Trans></span>
            </Link>
          </li>

          <li className={ this.isPathActive('/Faq') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/Faq">
              <span className="menu-title"><Trans>คำถามที่พบบ่อย</Trans></span>
            </Link>
          </li>
         
           
          <li className={ this.isPathActive('/setting-ui') ? 'nav-item active' : 'nav-item' }>
            <div className={ this.state.setingUiMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('setingUiMenuOpen') } data-toggle="collapse">
              <span className="menu-title"><Trans>การตั้งค่า</Trans></span>
              <i className="menu-arrow"></i>
             </div>
            <Collapse in={ this.state.setingUiMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/setting-ui/emailpage') ? 'nav-link active' : 'nav-link' } to="/setting-ui/emailpage"><Trans>ตั้งค่า ACC SendMail</Trans></Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/setting-ui/ev') ? 'nav-link active' : 'nav-link' } to="/setting-ui/ev"><Trans>แผนที่สถานีอัดประจุไฟฟ้า</Trans></Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/setting-ui/question') ? 'nav-link active' : 'nav-link' } to="/setting-ui/question"><Trans>แบบสอบถาม</Trans></Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/setting-ui/pdpa') ? 'nav-link active' : 'nav-link' } to="/setting-ui/pdpa"><Trans>นโยบายคุ้มครองข้อมูลส่วนบุคคล(PDPA)</Trans></Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/setting-ui/contact') ? 'nav-link active' : 'nav-link' } to="/setting-ui/contact"><Trans>ข้อมูลติดต่อเรา</Trans></Link></li>
              </ul>
            </Collapse>
          </li> */}
              </>
            ) : (
              <></>
            )
          ) : (
            <></>
          )}

          {this.state.login ? (
            this.state.login.role == "Client" ? (
              <>
                <li
                  className={
                    this.isPathActive("/mailbox")
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <Link className="nav-link" to="/mailbox">
                    <span className="menu-title">
                      <Trans>ข้อความร้องเรียน และ ข้อเสนอแนะ</Trans>
                    </span>
                  </Link>
                </li>
                <li
                  className={
                    this.isPathActive("/setting-app")
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <div
                    className={
                      this.state.setingUiMenuOpenApp
                        ? "nav-link menu-expanded"
                        : "nav-link"
                    }
                    onClick={() => this.toggleMenuState("setingUiMenuOpenApp")}
                    data-toggle="collapse"
                  >
                    <span className="menu-title">
                      <Trans>การจัดการข้อมูลเมนู เพิ่มเติม</Trans>
                    </span>
                    <i className="menu-arrow"></i>
                  </div>
                  <Collapse in={this.state.setingUiMenuOpenApp}>
                    <ul className="nav flex-column sub-menu">
                      <li className="nav-item">
                        <Link
                          className={
                            this.isPathActive("/setting-app/Blog")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          to="/setting-app/Blog"
                        >
                          <Trans>ประชาสัมพันธ์</Trans>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className={
                            this.isPathActive("/setting-app/Faq")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          to="/setting-app/Faq"
                        >
                          <Trans>คำถามที่พบบ่อย</Trans>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className={
                            this.isPathActive("/setting-app/question")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          to="/setting-app/question"
                        >
                          <Trans>แบบสอบถามเพื่อปรับปรุงระบบ</Trans>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className={
                            this.isPathActive("/setting-app/contact")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          to="/setting-app/contact"
                        >
                          <Trans>ข้อมูลการติดต่อ</Trans>
                        </Link>
                      </li>
                    </ul>
                  </Collapse>
                </li>
                <li
                  className={
                    this.isPathActive("/setting-ui")
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <div
                    className={
                      this.state.setingUiMenuOpen
                        ? "nav-link menu-expanded"
                        : "nav-link"
                    }
                    onClick={() => this.toggleMenuState("setingUiMenuOpen")}
                    data-toggle="collapse"
                  >
                    <span className="menu-title">
                      <Trans>การตั้งค่า</Trans>
                    </span>
                    <i className="menu-arrow"></i>
                  </div>
                  <Collapse in={this.state.setingUiMenuOpen}>
                    <ul className="nav flex-column sub-menu">
                      <li className="nav-item">
                        <Link
                          className={
                            this.isPathActive("/setting-ui/ev")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          to="/setting-ui/ev"
                        >
                          <Trans>แผนที่สถานีอัดประจุไฟฟ้า</Trans>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className={
                            this.isPathActive("/setting-ui/Routes")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          to="/setting-ui/Routes"
                        >
                          <Trans>กำหนดค่าผ่านทาง</Trans>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className={
                            this.isPathActive("/setting-ui/pdpa")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          to="/setting-ui/pdpa"
                        >
                          <Trans>นโยบายข้อมูลส่วนบุคคล</Trans>
                        </Link>
                      </li>
                      {/* <li className="nav-item"> <Link className={ this.isPathActive('/setting-ui/role') ? 'nav-link active' : 'nav-link' } to="/setting-ui/role"><Trans>การจัดการสิทธิการทำงาน</Trans></Link></li> */}
                    </ul>
                  </Collapse>
                </li>

                <li
                  className={
                    this.isPathActive("/member")
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <Link className="nav-link" to="/member">
                    <span className="menu-title">
                      <Trans>ข้อมูลสมาชิก</Trans>
                    </span>
                  </Link>
                </li>

                {/* <li className={ this.isPathActive('/mailbox') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/mailbox">
              <span className="menu-title"><Trans>ข้อความร้องเรียน และ ข้อเสนอแนะ</Trans></span>
            </Link>
          </li>
          <li className={ this.isPathActive('/member') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/member">
              <span className="menu-title"><Trans>ข้อมูลสมาชิก</Trans></span>
            </Link>
          </li>

          <li className={ this.isPathActive('/Blog') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/Blog">
              <span className="menu-title"><Trans>ประชาสัมพันธ์</Trans></span>
            </Link>
          </li>
          <li className={ this.isPathActive('/Routes') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/Routes">
              <span className="menu-title"><Trans>กำหนดค่าผ่านทาง</Trans></span>
            </Link>
          </li>

          <li className={ this.isPathActive('/Faq') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/Faq">
              <span className="menu-title"><Trans>คำถามที่พบบ่อย</Trans></span>
            </Link>
          </li>
         
           
          <li className={ this.isPathActive('/setting-ui') ? 'nav-item active' : 'nav-item' }>
            <div className={ this.state.setingUiMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('setingUiMenuOpen') } data-toggle="collapse">
              <span className="menu-title"><Trans>การตั้งค่า</Trans></span>
              <i className="menu-arrow"></i>
             </div>
            <Collapse in={ this.state.setingUiMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/setting-ui/ev') ? 'nav-link active' : 'nav-link' } to="/setting-ui/ev"><Trans>แผนที่สถานีอัดประจุไฟฟ้า</Trans></Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/setting-ui/question') ? 'nav-link active' : 'nav-link' } to="/setting-ui/question"><Trans>แบบสอบถาม</Trans></Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/setting-ui/pdpa') ? 'nav-link active' : 'nav-link' } to="/setting-ui/pdpa"><Trans>นโยบายคุ้มครองข้อมูลส่วนบุคคล(PDPA)</Trans></Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/setting-ui/contact') ? 'nav-link active' : 'nav-link' } to="/setting-ui/contact"><Trans>ข้อมูลติดต่อเรา</Trans></Link></li>
              </ul>
            </Collapse>
          </li> */}
              </>
            ) : (
              <></>
            )
          ) : (
            <></>
          )}

          {/* <li className={ this.isPathActive('/setting') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/setting">
              <span className="menu-title"><Trans>การตั้งค่า</Trans></span>
            </Link>
          </li> */}
          {/* <li className={ this.isPathActive('/basic-ui') ? 'nav-item active' : 'nav-item' }>
            <div className={ this.state.basicUiMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('basicUiMenuOpen') } data-toggle="collapse">
              <span className="menu-title"><Trans>Basic UI Elements</Trans></span>
              <i className="menu-arrow"></i>
              <i className="mdi mdi-crosshairs-gps menu-icon"></i>
            </div>
            <Collapse in={ this.state.basicUiMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/basic-ui/buttons') ? 'nav-link active' : 'nav-link' } to="/basic-ui/buttons"><Trans>Buttons</Trans></Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/basic-ui/dropdowns') ? 'nav-link active' : 'nav-link' } to="/basic-ui/dropdowns"><Trans>Dropdowns</Trans></Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/basic-ui/typography') ? 'nav-link active' : 'nav-link' } to="/basic-ui/typography"><Trans>Typography</Trans></Link></li>
              </ul>
            </Collapse>
          </li>
          <li className={ this.isPathActive('/form-elements') ? 'nav-item active' : 'nav-item' }>
            <div className={ this.state.formElementsMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('formElementsMenuOpen') } data-toggle="collapse">
              <span className="menu-title"><Trans>Form Elements</Trans></span>
              <i className="menu-arrow"></i>
              <i className="mdi mdi-format-list-bulleted menu-icon"></i>
            </div>
            <Collapse in={ this.state.formElementsMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/form-elements/basic-elements') ? 'nav-link active' : 'nav-link' } to="/form-elements/basic-elements"><Trans>Basic Elements</Trans></Link></li>
              </ul>
            </Collapse>
          </li>
          <li className={ this.isPathActive('/tables') ? 'nav-item active' : 'nav-item' }>
            <div className={ this.state.tablesMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('tablesMenuOpen') } data-toggle="collapse">
              <span className="menu-title"><Trans>Tables</Trans></span>
              <i className="menu-arrow"></i>
              <i className="mdi mdi-table-large menu-icon"></i>
            </div>
            <Collapse in={ this.state.tablesMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/tables/basic-table') ? 'nav-link active' : 'nav-link' } to="/tables/basic-table"><Trans>Basic Table</Trans></Link></li>
              </ul>
            </Collapse>
          </li>
          <li className={ this.isPathActive('/icons') ? 'nav-item active' : 'nav-item' }>
            <div className={ this.state.iconsMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('iconsMenuOpen') } data-toggle="collapse">
              <span className="menu-title"><Trans>Icons</Trans></span>
              <i className="menu-arrow"></i>
              <i className="mdi mdi-contacts menu-icon"></i>
            </div>
            <Collapse in={ this.state.iconsMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/icons/mdi') ? 'nav-link active' : 'nav-link' } to="/icons/mdi"><Trans>Material</Trans></Link></li>
              </ul>
            </Collapse>
          </li>
          <li className={ this.isPathActive('/charts') ? 'nav-item active' : 'nav-item' }>
            <div className={ this.state.chartsMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('chartsMenuOpen') } data-toggle="collapse">
              <span className="menu-title"><Trans>Charts</Trans></span>
              <i className="menu-arrow"></i>
              <i className="mdi mdi-chart-bar menu-icon"></i>
            </div>
            <Collapse in={ this.state.chartsMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/charts/chart-js') ? 'nav-link active' : 'nav-link' } to="/charts/chart-js"><Trans>Chart Js</Trans></Link></li>
              </ul>
            </Collapse>
          </li>
          <li className={ this.isPathActive('/user-pages') ? 'nav-item active' : 'nav-item' }>
            <div className={ this.state.userPagesMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('userPagesMenuOpen') } data-toggle="collapse">
              <span className="menu-title"><Trans>User Pages</Trans></span>
              <i className="menu-arrow"></i>
              <i className="mdi mdi-lock menu-icon"></i>
            </div>
            <Collapse in={ this.state.userPagesMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/user-pages/login-1') ? 'nav-link active' : 'nav-link' } to="/user-pages/login-1"><Trans>Login</Trans></Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/user-pages/register-1') ? 'nav-link active' : 'nav-link' } to="/user-pages/register-1"><Trans>Register</Trans></Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/user-pages/lockscreen') ? 'nav-link active' : 'nav-link' } to="/user-pages/lockscreen"><Trans>Lockscreen</Trans></Link></li>
              </ul>
            </Collapse>
          </li>
          <li className={ this.isPathActive('/error-pages') ? 'nav-item active' : 'nav-item' }>
            <div className={ this.state.errorPagesMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('errorPagesMenuOpen') } data-toggle="collapse">
              <span className="menu-title"><Trans>Error Pages</Trans></span>
              <i className="menu-arrow"></i>
              <i className="mdi mdi-security menu-icon"></i>
            </div>
            <Collapse in={ this.state.errorPagesMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/error-pages/error-404') ? 'nav-link active' : 'nav-link' } to="/error-pages/error-404">404</Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/error-pages/error-500') ? 'nav-link active' : 'nav-link' } to="/error-pages/error-500">500</Link></li>
              </ul>
            </Collapse>
          </li>
          <li className={ this.isPathActive('/general-pages') ? 'nav-item active' : 'nav-item' }>
            <div className={ this.state.generalPagesMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('generalPagesMenuOpen') } data-toggle="collapse">
              <span className="menu-title"><Trans>General Pages</Trans></span>
              <i className="menu-arrow"></i>
              <i className="mdi mdi-medical-bag menu-icon"></i>
            </div>
            <Collapse in={ this.state.generalPagesMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/general-pages/blank-page') ? 'nav-link active' : 'nav-link' } to="/general-pages/blank-page"><Trans>Blank Page</Trans></Link></li>
              </ul>
            </Collapse>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="http://bootstrapdash.com/demo/purple-react-free/documentation/documentation.html" rel="noopener noreferrer" target="_blank">
              <span className="menu-title"><Trans>Documentation</Trans></span>
              <i className="mdi mdi-file-document-box menu-icon"></i>
            </a>
          </li> */}
        </ul>
      </nav>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  componentDidMount() {
    this.onRouteChanged();
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector("body");
    document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
      el.addEventListener("mouseover", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });
  }
}

export default withRouter(Sidebar);
