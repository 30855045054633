import axios from "axios";
import AppConfig from "./AppConfig.js";
 

const http2 = axios.create({
    baseURL: AppConfig.api,
    timeout: 30000,
    headers: {
      "Access-Control-Allow-Origin": "*",
      'Content-Type': 'multipart/form-data'
    },
});

export default http2;