const AppConfig = {
    api:"http://www.smartertollway.com/DMT",
    main_api:"http://www.smartertollway.com",
    // api:"http://172.16.111.1/DMT",
    // main_api:"http://172.16.111.1",
    // api:"http://150.95.83.27/DMT"
    // api:"http://localhost:5000/DMT"
    // api:"http://10.0.2.2:5000/DMT"
    // api:"http://10.0.2.2/DMT"
};

export default AppConfig;